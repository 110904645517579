import PNotify from "pnotify/dist/es/PNotify";
import PNotifyButtons from "pnotify/dist/es/PNotifyButtons";

window.PNotify = PNotify;

PNotify.defaults.styling = "bootstrap4";
PNotify.defaults.icons = "fontawesome4";
PNotify.defaults.stack = {
  dir1: "down",
  dir2: "left",
  firstpos1: 100,
  firstpos2: 50
};
PNotify.defaults.delay = 5000;
